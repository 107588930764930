:root {
  --red: #d1060e;
  --gray: #bababa;
  --azulito: #f3f7fb;
}
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}
header {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

header .logo {
  width: 130px;
  margin-left: 10px;
}

.Titulo-App {
  text-align: center;
}

.Titulo-App > :first-child {
  color: var(--red);
}

.Titulo-App > :nth-child(2) {
  margin-top: -20px;
  color: var(--gray);
  border-bottom: 3px solid var(--gray);
}

.container {
  padding: 10px;
  height: 100vh;
  margin-bottom: 20px;
}
.btn-logout {
  outline: none;
  border: 3px solid var(--red);
  background-color: white;
  padding: 10px;
  border-radius: 10px 0 10px 10px;
}
