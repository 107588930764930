.container-ver-prescripcion {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}

.container-ver-prescripcion h1 {
    text-align: center;
}