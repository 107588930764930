label {
    cursor: pointer;
}

textarea {
    outline: none;
    border: 1px solid var(--gray);
}

textarea:focus {
    border: 1px solid var(--red);
    transition: .3s;
}

.container-prescripciones {
    text-align: center;
}

.contenedor-productos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.producto {
    cursor: pointer;
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 5px;
    padding: 5px;
    background-color: var(--azulito);
}

.producto img {
    width: 10em;
}

.container-info-prescripcion {
    display: flex;
    text-align: left;
    flex-direction: column;
}

.container-info-prescripcion h3 {
    font-size: 14px;
}

.fecha,
.paciente {
    display: flex;
}

.recuadros-fecha,
.recuadros-paciente {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.recuadros-fecha input {
    width: 20px;
    border: none;
    height: 10px;
    background-color: var(--azulito);
    padding: 10px;
    border-radius: 5px;
    margin-right: 20px;
}

.recuadros-paciente input {
    width: 100%;
    border: none;
    height: 10px;
    background-color: var(--azulito);
    padding: 10px;
    border-radius: 5px;
    margin-right: 20px;
}

.recuadros-paciente input:focus {
    border: 1px solid var(--red);
    transition: .3s;
}

.firma-logo {
    justify-content: center;
    display: flex;
}

.firma-logo p {
    color: #939393;
    margin-right: 30px;
    width: 100px;
    text-align: center;
    padding: 20px;
    border-radius: 15px;
    background-color: var(--azulito);
}

.contenedor-input {
    width: 100%;
    display: flex;
    justify-content: center;
}

.generar-prescripcion {
    border: 2px solid var(--red);
    border-radius: 10px 0 10px 10px;
    padding: 20px;
    margin-top: 15px;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    background-color: white;
    outline: none;
}

.producto-seleccionado {
    display: flex;
    color: var(--gray);
}

.producto-seleccionado h1 {
    margin-left: 10px;
}

.producto-seleccionado img {
    width: 120px;
}

.Firma, .Logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Firma b, .Logo b {
    text-align: center;
}

@media screen and (max-width: 859px) {
    .producto img {
        width: 4em;
    }

    .producto {
        width: 5em;
    }

    .producto > p {
        font-size: 12px;
    }

    textarea {
        width: 100%
    }
    .producto-seleccionado h1{
        font-size: 16px;
    }
}

@media screen and (min-width: 1024px) {
    .recuadros-fecha input {
        width: 30px;
    }

    .container-info-prescripcion {
        align-items: center;
    }
}