.container-login {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 8em;
}

input {
  outline: none;
  height: 30px;
  width: 280px;
  padding: 10px;
  border: 2px solid var(--red);
  border-radius: 50px 0 50px 50px;
}
.form-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form-login input[type="text"] {
  margin-bottom: 2em;
}
.form-login input[type="submit"] {
  padding: 0;
  width: 10em;
  background-color: var(--red);
  color: white;
  border-radius: 50px;
  margin-bottom: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
