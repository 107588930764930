.container-popup {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    background: rgba(0, 0, 0, 0.71);
    justify-content: center;
}

.contenedor-componente {
    background-color: white;
    border-radius: 30px;
    height: 50%;
    width: 35%;
    margin-top: 130px;
}
.contenedor-componente img{
    margin-top: 30px;
    width: 30%;
    animation: 2s rotate360 infinite linear;
}
@keyframes rotate360 {
    to { transform: rotate(360deg); }
}

@media screen and (max-width: 859px) {

    .contenedor-componente{
        margin-top: 80px;
        width: 80%;
        height: 70%;
        padding: 0 20px;
    }
}